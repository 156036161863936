import { NoDataContainerSelector } from '@/components/NoDataContainer/NoDataContainer';
import { getSelectedNode } from '@/pages/documents/DocumentsList/selector';
import { createSelector } from 'reselect';

export const getNoSelectedNode: NoDataContainerSelector = createSelector([getSelectedNode], (selectedNode) => ({
  loading: false,
  loaded: true,
  hasData: Boolean(selectedNode),
  error: undefined,
}));

export const getNoDocumentPreviews: NoDataContainerSelector = createSelector([getSelectedNode], (selectedNode) => ({
  loading: false,
  loaded: true,
  hasData: Boolean(selectedNode?.data?.image_links.length !== 0),
  error: undefined,
}));
