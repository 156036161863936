import { Route } from '@core-ui/redux-router';
import { ROUTES } from '@/app/consts/routes';
import React from 'react';
import { Redirect } from 'react-router-dom';

const key = 'root';

const routes: Route[] = [
  {
    key,
    exact: true,
    path: '/',
    component: () => <Redirect to={`/${ROUTES.LOGIN}`} />,
  },
];

export default routes;
