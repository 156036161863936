import State from '@/app/types/state';
import { RenameNodeModalData } from '@/pages/documents/Modals/command';
import { getLoading } from '@/pages/documents/Modals/selector';
import { createSelector } from 'reselect';

const getModalsData = (state: State) => state.documents.modals.data as RenameNodeModalData;

export default createSelector([getModalsData, getLoading], (modalData, loading) => ({
  node: modalData?.node,
  nodeName: modalData?.node?.text?.split('.').shift(),
  loading,
}));
