import { useQuery } from '@core-ui/redux-router';
import { HEIGHT_SIZE } from '@core-ui/styles';
import { updateQuery } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import { DOCUMENTS_STATUS_OPTIONS } from '@/pages/documents/const';
import DocumentInfo from '@/pages/documents/DocumentInfo';
import DocumentPreview from '@/pages/documents/DocumentPreview';
import DocumentsList from '@/pages/documents/DocumentsList';
import DocumentsModals from '@/pages/documents/Modals';
import { IDocumentListQueryParams } from '@/pages/documents/types';
import React, { CSSProperties, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const containerStyle: CSSProperties = {
  height: `calc(100vh - ${HEIGHT_SIZE.headerHeight}px)`,
  overflow: 'hidden',
  position: 'relative',
};

const DocumentsRoot = () => {
  const query = useQuery<IDocumentListQueryParams>();
  const history = useHistory();

  useEffect(() => {
    if (!query.status) {
      history.replace(updateQuery({ status: DOCUMENTS_STATUS_OPTIONS.ALL }));
    }
  }, []);

  return (
    <Grid container justifyContent="space-between" wrap="nowrap" sx={containerStyle}>
      <DocumentsList />

      <DocumentPreview />

      <DocumentInfo />

      <DocumentsModals />
    </Grid>
  );
};

export default DocumentsRoot;
