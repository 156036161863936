import { COLOURS } from '@core-ui/styles';
import { IMenuOption } from '@/components/Menu/Menu';
import MoreButton from '@/components/MoreButton';
import { useAppSelector } from '@/hooks/useAppSelector';
import { FLEET } from '@/pages/documents/const';
import { uploadFiles } from '@/pages/documents/DocumentsList/actions';
import { getNodeUpdating } from '@/pages/documents/DocumentsList/selector';
import { openDocumentsModal } from '@/pages/documents/Modals/actions';
import { CreateFolderModalData, DeleteNodeModalData, RenameNodeModalData } from '@/pages/documents/Modals/command';
import { FileTreeNode } from '@/pages/documents/types';
import { DownloadSimple, NotePencil, PlusCircle, Trash, UploadSimple } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';

interface IProps {
  node: FileTreeNode;
  enableFileTreeFunctions: boolean;
}

const NodeMoreButton: FC<IProps> = (props) => {
  const { node, enableFileTreeFunctions } = props;

  const nodeUpdating = useAppSelector(getNodeUpdating);
  const dispatch = useDispatch();

  const handleCreateNewFolder = () => {
    if (!nodeUpdating) {
      dispatch(openDocumentsModal(new CreateFolderModalData(Number(node.id), node.data?.shared ?? false)));
    }
  };

  const handleUploadFiles = () => {
    if (nodeUpdating) {
      return;
    }

    const fileInput = document.createElement('input');

    const uploadFile = (e: Event) => {
      if (!node?.data || !e.target) {
        return;
      }

      const fileList = (e.target as HTMLInputElement).files;

      if (!fileList) {
        return;
      }

      const files = Object.values(fileList).map((file) => file);

      dispatch(uploadFiles({ files, parent: node.id, shared: node.data.shared }));

      fileInput.removeEventListener('input', uploadFile);
      document.body.removeChild(fileInput);
    };

    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.style.display = 'none';
    document.body.appendChild(fileInput);

    fileInput.addEventListener('input', uploadFile);
    fileInput.click();
  };

  const handleDownload = () => {
    if (!node.data?.link) {
      return;
    }

    window.open(node.data.link);
  };

  const handleRename = () => {
    if (nodeUpdating) {
      return;
    }

    dispatch(openDocumentsModal(new RenameNodeModalData(node)));
  };

  const handleDelete = () => {
    if (nodeUpdating) {
      return;
    }

    dispatch(openDocumentsModal(new DeleteNodeModalData(node)));
  };

  const editOptions: IMenuOption[] = [
    {
      title: <FormattedMessage id="label.create_new_folder" />,
      dataTestId: 'create-new-folder-option',
      icon: <PlusCircle />,
      onClick: handleCreateNewFolder,
      visible: enableFileTreeFunctions && Boolean(node.droppable),
    },
    {
      title: <FormattedMessage id="label.upload_files" />,
      dataTestId: 'upload-files-option',
      icon: <UploadSimple />,
      onClick: handleUploadFiles,
      visible: enableFileTreeFunctions && Boolean(node.droppable),
    },
    {
      title: <FormattedMessage id="label.download" />,
      dataTestId: 'download-option',
      icon: <DownloadSimple />,
      onClick: handleDownload,
      visible: !node.droppable,
    },
    {
      title: <FormattedMessage id="label.rename" />,
      dataTestId: 'rename-option',
      icon: <NotePencil />,
      onClick: handleRename,
      visible: node.id !== FLEET,
    },
    {
      title: <FormattedMessage id="label.delete" />,
      dataTestId: 'delete-option',
      textColor: COLOURS.Strawberry.Solid[600],
      icon: <Trash color={COLOURS.Strawberry.Solid[600]} />,
      onClick: handleDelete,
      visible: node.id !== FLEET,
    },
  ];

  return (
    <MoreButton
      options={editOptions}
      variant="square"
      disabled={nodeUpdating}
      dataTestId="node-more-button"
      styles={{
        height: '100%',
        background: 'transparent !important',
        borderColor: 'transparent !important',
        cursor: nodeUpdating ? 'wait' : 'pointer !important',

        '&:hover': {
          background: 'transparent !important',
          borderColor: 'transparent !important',
        },
      }}
    />
  );
};

export default NodeMoreButton;
