import { setChatAuthorization } from '@/api/chatApi';
import { POST, setOceanAuthorization } from '@/api/oceanApi';
import { chatTokenName, oceanTokenName, setToken } from '@/api/token-store';
import { initApp } from '@/app/actions';
import { responseError } from '@/app/sagas';
import { LOGIN_ROUTE } from '@/pages/login/consts';
import { ILoginForm, ILoginResponse } from '@/pages/login/types';
import { Action } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* login({ payload }: Action<ILoginForm>) {
  try {
    yield all([put(actions.setLoginErrors(false)), put(actions.setLoginFormLoading({ loading: true }))]);

    const formData = new FormData();

    formData.append('username', payload.email);
    formData.append('password', payload.password);

    const response: ILoginResponse = yield POST(LOGIN_ROUTE, formData);

    setOceanAuthorization(response.access_token);
    setToken(oceanTokenName, response.access_token);

    setChatAuthorization(response.ocean_chat);
    setToken(chatTokenName, response.ocean_chat);

    yield put(initApp());
  } catch (e) {
    // loading изменяется на false только при неудачном запросе чтобы при удачном запросе состояние загрузки
    // отображалось вплоть до завершения редиректа на новую страницу. Если loading изменять на false при
    // удачном запросе, то когда состояние загрузки уже изменится на false, редирект ещё не будет завершён
    // и интерфейс на секунду покажется пользователю зависшим
    yield all([
      put(actions.setLoginFormLoading({ loading: false })),
      put(actions.setLoginErrors(true)),
      call(responseError, e),
    ]);
  }
}

export default [takeLatest(actions.login, login)];
