import { asMutable } from '@core-ui/immutable';
import { Nullable } from '@core-ui/types';
import { buildURL, getParsedQuery, updateQuery } from '@core-ui/url';
import { DropOptions } from '@minoru/react-dnd-treeview';
import { GET, PATCH, POST } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { vehicleIdSelector } from '@/app/selectors';
import { ISagaContext } from '@/app/types/common';
import { throwError } from '@/app/utils/app';
import { showNotification } from '@/components/Notification/actions';
import { ChangeFileStorageNodeModel, CreateFolderModel, FileStorageNodeData, FileStorageNodeSchema } from '@/generated';
import history from '@/history';
import { DOCUMENTS_REQUEST_QUERY_PARAMS, DOCUMENTS_STATUS_OPTIONS, FILE_STORAGE, FLEET } from '@/pages/documents/const';
import { getCurrentItems } from '@/pages/documents/DocumentsList/selector';
import { closeDocumentsModal, setDocumentsModalLoading } from '@/pages/documents/Modals/actions';
import { FileTreeNode, IDocumentListQueryParams } from '@/pages/documents/types';
import { buildChildrenIdArray, buildParentsIdArray } from '@/pages/documents/utils';
import { all } from '@redux-saga/core/effects';
import isNil from 'lodash-es/isNil';
import { Action } from 'redux-actions';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { addSingleNode, ICreateFolder, setNodeUpdating } from './actions';

const createFormData = (payload: actions.IUploadFiles, boatId: string) => {
  const formData = new FormData();
  const parent = payload.parent === FLEET ? 0 : payload.parent;

  formData.append('boat_id', boatId);
  formData.append('parent', String(parent));
  formData.append('shared', String(payload.shared));

  payload.files.forEach((file) => {
    formData.append('fileobjects', file);
  });

  return formData;
};

function* getDocumentsList() {
  try {
    yield put(actions.setDocumentsListLoading({ loading: true }));

    const boatId: number = yield select(vehicleIdSelector);
    const query = getParsedQuery<IDocumentListQueryParams>();

    const nodeList: FileStorageNodeSchema[] = yield call(GET, `/${FILE_STORAGE}`, {
      [DOCUMENTS_REQUEST_QUERY_PARAMS.BOAT_ID]: boatId,
      [DOCUMENTS_REQUEST_QUERY_PARAMS.STATUS]: query.status ?? DOCUMENTS_STATUS_OPTIONS.ALL,
    });

    const formattedList: FileTreeNode[] = nodeList.map((node) => ({
      ...node,
      // backend has special file type for folder, and we check for it here to assign
      // droppable: true only for folders
      droppable: node.data.file_type === 'folder',
      // if node is shared and belongs to the root of the tree, then it belongs to the
      // fleet node which is used as a pseudo-root for all shared nodes
      parent: node.data.shared && node.parent === 0 ? FLEET : node.parent,
    }));

    yield put(
      actions.setDocumentsSectionList({
        value: formattedList,
        hasData: Boolean(formattedList.length),
        isAllDataReceived: true,
      })
    );

    if (query.id) {
      const selectedNode = formattedList.find((node) => node.id === Number(query.id));

      if (selectedNode) {
        const initialSelectedNodesIds = buildParentsIdArray(formattedList, selectedNode, []);

        yield all([
          put(actions.setSelectedNode(selectedNode)),
          put(actions.setInitialOpenedNodes(initialSelectedNodesIds)),
        ]);
      }
    }
  } catch (e) {
    yield all([
      call(responseError, e),
      put(
        actions.setDocumentsSectionList({
          error: e as Error,
          hasData: false,
          isAllDataReceived: true,
        })
      ),
    ]);
  }
}

function* changeSelectedNode({ payload }: Action<Nullable<FileTreeNode>>) {
  try {
    const history: ISagaContext['history'] = yield getContext('history');

    history.replace(
      updateQuery<IDocumentListQueryParams>({
        id: String(payload?.id),
      })
    );

    yield put(actions.setSelectedNode(payload));
  } catch (e) {
    yield call(responseError, e);
  }
}

function* updateNodeParent({ payload }: Action<DropOptions<FileStorageNodeData>>) {
  try {
    const { dragSourceId, dragSource, dropTargetId, dropTarget } = payload;

    yield all([
      put(setNodeUpdating(true)),
      put(
        showNotification({
          variant: 'info',
          titleId: 'notification.info.text.updating_documents_tree',
        })
      ),
    ]);

    const boatId: number = yield select(vehicleIdSelector);
    const currentItems: Nullable<FileTreeNode[]> = yield select(getCurrentItems);
    const mutableCurrentItems = asMutable(currentItems, { deep: true });

    const updatedItemIndex = mutableCurrentItems?.findIndex((item) => item.id === dragSourceId);

    if (mutableCurrentItems && !isNil(updatedItemIndex)) {
      const url = buildURL(`/${FILE_STORAGE}/${payload.dragSourceId}`, {
        [DOCUMENTS_REQUEST_QUERY_PARAMS.BOAT_ID]: boatId,
      });

      const updatedNode: FileStorageNodeSchema = yield call(PATCH, url, {
        // if target node is fleet, then in request send parent = 0 since fleet
        // is a pseudo-root node which exist only on the frontend
        text: dragSource?.text,
        parent: dropTargetId === FLEET ? 0 : dropTargetId,
        data: {
          shared: dropTarget?.data?.shared ?? false,
        },
      } as ChangeFileStorageNodeModel);

      const formattedUpdatedNode: FileTreeNode = {
        ...updatedNode,
        droppable: updatedNode.data.file_type === 'folder',
        parent: updatedNode.data.shared && updatedNode.parent === 0 ? FLEET : dropTargetId,
        data: {
          ...updatedNode.data,
          shared: dropTarget?.data?.shared ?? false,
        },
      };

      mutableCurrentItems[updatedItemIndex] = formattedUpdatedNode;

      if (formattedUpdatedNode.droppable) {
        const childrenIds = buildChildrenIdArray(mutableCurrentItems, formattedUpdatedNode, []);

        childrenIds.forEach((childId) => {
          const childIndex = mutableCurrentItems?.findIndex((item) => item.id === childId);

          mutableCurrentItems[childIndex].data!.shared = formattedUpdatedNode.data?.shared ?? false;
        });
      }

      yield all([
        put(
          actions.setDocumentsSectionList({
            value: mutableCurrentItems,
            hasData: true,
            isAllDataReceived: true,
          })
        ),
        put(
          showNotification({
            variant: 'success',
            titleId: 'notification.success.text.updating_documents_tree',
          })
        ),
      ]);
    }
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setNodeUpdating(false));
  }
}

function* createFolder({ payload }: Action<ICreateFolder>) {
  try {
    const { parent, text, shared } = payload;

    yield put(setDocumentsModalLoading({ loading: true }));

    if (!text || text === '') {
      throwError('Name must not be empty');
    }

    const boatId: number = yield select(vehicleIdSelector);

    const url = buildURL(`/${FILE_STORAGE}/folder`);

    const newNode: FileStorageNodeSchema = yield call(POST, url, {
      text,
      boat_id: boatId,
      shared,
      // if target node is fleet, then in request send parent = 0 since fleet
      // is a pseudo-root node which exist only on the frontend
      parent: parent === FLEET ? 0 : parent,
    } as CreateFolderModel);

    const formattedNode: FileTreeNode = {
      ...newNode,
      droppable: true,
      // if node is shared and belongs to the root of the tree, then it belongs to the
      // fleet node with which is used as a pseudo-root for all shared nodes
      parent: newNode.data.shared && newNode.parent === 0 ? FLEET : newNode.parent,
    };

    yield all([put(addSingleNode(formattedNode)), put(closeDocumentsModal())]);
  } catch (e) {
    yield all([call(responseError, e), put(setDocumentsModalLoading({ loading: false }))]);
  }
}

function* uploadFiles({ payload }: Action<actions.IUploadFiles>) {
  try {
    yield all([
      put(setNodeUpdating(true)),
      put(
        showNotification({
          variant: 'info',
          titleId: 'notification.info.text.uploading_files',
          message: '',
        })
      ),
    ]);

    const boatId: number = yield select(vehicleIdSelector);
    const currentItems: Nullable<FileTreeNode[]> = yield select(getCurrentItems);

    const formData = createFormData(payload, String(boatId));

    const newNodes: FileStorageNodeSchema[] = yield call(POST, `${FILE_STORAGE}/upload_files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const formattedNewItems: FileTreeNode[] = newNodes.map((node) => ({
      ...node,
      droppable: false,
      // if node is shared and belongs to the root of the tree, then it belongs to the
      // fleet node with which is used as a pseudo-root for all shared nodes
      parent: node.data.shared && node.parent === 0 ? FLEET : node.parent,
    }));

    const updatedItems = [...(currentItems ?? []), ...formattedNewItems];

    yield all([
      put(
        actions.setDocumentsSectionList({
          value: updatedItems,
          hasData: Boolean(updatedItems.length),
          isAllDataReceived: true,
        })
      ),
      put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success.text.files_uploaded',
          message: '',
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setNodeUpdating(false));
  }
}

export default [
  takeLatest(actions.getDocumentsSectionList, getDocumentsList),
  takeLatest(actions.changeSelectedNode, changeSelectedNode),
  takeLatest(actions.updateNodeParent, updateNodeParent),
  takeLatest(actions.createFolder, createFolder),
  takeLatest(actions.uploadFiles, uploadFiles),
];
