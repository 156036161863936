import { Route } from '@core-ui/redux-router';
import { ROUTES } from '@/app/consts/routes';
import MobileRoot from '@/pages/mobile/MobilePlaceholderRoot';

const key = 'mobile';

const routes: Route[] = [
  {
    key,
    path: `/:route(${ROUTES.MOBILE})`,
    exact: true,
    component: MobileRoot,
  },
];

export default routes;
