import Button from '@core-ui/button';
import { AppStoreBadge, PlayStoreBadge } from '@core-ui/icons';
import Grid from '@mui/material/Grid';
import React from 'react';
import { APP_STORE_APP_URL, CLIENT_DEVICE_OS, PLAY_STORE_APP_URL } from '@/app/consts/app';
import { getDeviceOS } from '@/app/utils/app';

const playStoreBadge = <Button label={<PlayStoreBadge />} href={PLAY_STORE_APP_URL} />;

const appStoreBadge = <Button label={<AppStoreBadge />} href={APP_STORE_APP_URL} />;

const MobileBadges = () => {
  const deviceOS = getDeviceOS();

  let content: JSX.Element;

  switch (deviceOS) {
    case CLIENT_DEVICE_OS.ANDROID:
      content = playStoreBadge;
      break;

    case CLIENT_DEVICE_OS.IOS:
      content = appStoreBadge;
      break;

    default:
      content = (
        <>
          {playStoreBadge}
          {appStoreBadge}
        </>
      );
  }

  return (
    <Grid container direction="column" gap={4}>
      {content}
    </Grid>
  );
};

export default MobileBadges;
