import { resetState, setLoading } from '@core-ui/reducers_handlers';
import { ILoginForm } from '@/pages/login/types';
import { createAction } from 'redux-actions';

export const reducerName = 'login.form';

export const login = createAction<ILoginForm>(`${reducerName}.LOGIN`);

export const toggleShowPassword = createAction(`${reducerName}.TOGGLE_SHOW_PASSWORD`);

export const setLoginFormLoading = setLoading(reducerName);
export const resetLoginFormLoading = resetState(reducerName);

export const setLoginErrors = createAction<boolean>(`${reducerName}.SET_LOGIN_ERRORS`);
