import reducer, { List } from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { FileTreeNode } from '@/pages/documents/types';
import { Action, handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import * as actions from './actions';

export interface IDocumentsListState extends List<FileTreeNode> {
  selectedNode: Nullable<FileTreeNode>;
  initialOpenedNodes: (number | string)[];
  nodeUpdating: boolean;
}

const defaultState = Immutable<IDocumentsListState>({
  loaded: false,
  hasData: false,
  loading: false,
  chunkLoading: false,
  isAllDataReceived: false,
  selectedNode: null,
  initialOpenedNodes: [],
  nodeUpdating: false,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.setSelectedNode.toString()]: (state, { payload }: Action<Nullable<FileTreeNode>>) => {
      return state.set('selectedNode', payload);
    },
    [actions.setInitialOpenedNodes.toString()]: (state, { payload }: Action<(number | string)[]>) => {
      return state.set('initialOpenedNodes', payload);
    },
    [actions.setNodeUpdating.toString()]: (state, { payload }: Action<boolean>) => {
      return state.set('nodeUpdating', payload);
    },
    [actions.deleteSingleNode.toString()]: (state, { payload }: Action<FileTreeNode>) => {
      const updatedItems = state.value?.filter((item) => item.id !== payload.id);

      return state.set('value', updatedItems);
    },
    [actions.deleteMultipleNodes.toString()]: (state, { payload }: Action<number[]>) => {
      const updatedItems = state.value?.filter((item) => !payload.includes(Number(item.id)));

      return state.set('value', updatedItems);
    },
    [actions.addSingleNode.toString()]: (state, { payload }: Action<FileTreeNode>) => {
      return state.set('value', state.value?.concat(payload));
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
