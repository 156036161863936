import Button from '@core-ui/button';
import { COLOURS } from '@core-ui/styles';
import { useDragOver } from '@minoru/react-dnd-treeview';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { FLEET } from '@/pages/documents/const';
import NodeMoreButton from '@/pages/documents/DocumentsList/components/NodeMoreButton';
import { FileTreeNode } from '@/pages/documents/types';
import { CaretDown, DotsSixVertical } from '@phosphor-icons/react';
import React, { FC, RefObject } from 'react';
import NodeIcon from './NodeIcon';
import useStyles from './styles';

export interface IProps {
  node: FileTreeNode;
  nodeUpdating: boolean;
  depth: number;
  isOpen: boolean;
  isSelected: boolean;
  handleRef: RefObject<HTMLDivElement>;
  onToggle: (id: FileTreeNode['id']) => void;
  onSelect: (node: FileTreeNode) => void;
}

const SingleNode: FC<IProps> = (props) => {
  const { node, nodeUpdating, depth, handleRef, isOpen, isSelected, onToggle, onSelect } = props;

  const styles = useStyles(props);
  const dragOverProps = useDragOver(node.id, isOpen, onToggle);

  const handleNodeClick = (e: React.MouseEvent) => {
    if (node.droppable) {
      e.stopPropagation();
      onToggle(node.id);
    } else if (!nodeUpdating && !isSelected) {
      onSelect(node);
    }
  };

  const handleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    onToggle(node.id);
  };

  // each level is indented by 8px, so we multiply the depth by 2 because we're using 4px as the base unit
  const indent = depth * 2;

  const textFontWeight = (node.droppable && isOpen) || isSelected ? 'semiBold' : undefined;

  return (
    <Grid
      item
      container
      wrap="nowrap"
      alignItems="flex-start"
      marginBottom={1}
      borderRadius={2}
      ref={handleRef}
      sx={styles.container}
      {...dragOverProps}
    >
      {node.id === FLEET ? (
        <Grid item padding={2} sx={styles.fleetEmptyBox} onClick={handleNodeClick} />
      ) : (
        <Grid item padding={2} sx={styles.dragIcon}>
          <SvgIcon>
            <DotsSixVertical />
          </SvgIcon>
        </Grid>
      )}

      <Grid
        item
        container
        gap={2}
        alignItems="flex-start"
        wrap="nowrap"
        sx={styles.innerContainer}
      >
        <Grid
          item
          container
          gap={3}
          wrap="nowrap"
          alignItems="flex-start"
          width="auto"
          flex={1}
          paddingY={2}
          onClick={handleNodeClick}
          sx={styles.content}
        >
          <Grid item sx={{ paddingInlineStart: indent }}>
            <NodeIcon node={node} isOpen={isOpen} isSelected={isSelected} />
          </Grid>

          <Grid item>
            <Typography
              fontWeight={textFontWeight}
              color={isSelected ? COLOURS.Fig.Solid[600] : 'primary'}
              sx={styles.text}
            >
              {node.text}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container gap={1} wrap="nowrap" width="auto" marginLeft="auto">
          <NodeMoreButton node={node} enableFileTreeFunctions />

          {node.droppable && (
            <Button
              variant="text"
              onClick={handleExpand}
              icon={
                <SvgIcon sx={styles.expandButtonIcon}>
                  <CaretDown />
                </SvgIcon>
              }
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SingleNode;
