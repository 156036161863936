import State from '@/app/types/state';
import { convertValueToUTCDate } from '@/app/utils/dates';
import { NoDataContainerSelector } from '@/components/NoDataContainer/NoDataContainer';
import { getDocumentsList, getSelectedNode } from '@/pages/documents/DocumentsList/selector';
import { createSelector } from 'reselect';

export const getNoDataContainerData: NoDataContainerSelector = createSelector(
  [getDocumentsList, getSelectedNode],
  (list, selectedNode) => ({
    loading: list.loading,
    loaded: list.loaded,
    hasData: Boolean(selectedNode),
    error: list.error,
  })
);

const getDocumentsInfoLoading = (state: State) => state.documents.info.loading;

export const getDocumentsInfoFormData = createSelector(
  [getSelectedNode, getDocumentsInfoLoading],
  (selectedNode, loading) => ({
    node: selectedNode,
    loading,
    initialValues: {
      name: selectedNode?.text?.split('.').shift(),
      expires_at: selectedNode?.data?.expires_at ? convertValueToUTCDate(selectedNode?.data?.expires_at) : null,
      description: selectedNode?.data?.description,
    },
  })
);
