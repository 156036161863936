import Button from '@core-ui/button';
import { ScrollMemory } from '@core-ui/redux-router';
import { Nullable } from '@core-ui/types';
import { DropOptions } from '@minoru/react-dnd-treeview';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import LoadFileDropzone from '@/components/LoadFileDropzone';
import NoDataContainer from '@/components/NoDataContainer';
import NoDataPlaceholder from '@/components/NoDataPlaceholder';
import { FileStorageNodeData } from '@/generated';
import { useAppSelector } from '@/hooks/useAppSelector';
import { changeSelectedNode, updateNodeParent, uploadFiles } from '@/pages/documents/DocumentsList/actions';
import NodeTree from '@/pages/documents/DocumentsList/components/NodeTree';
import ListSkeleton from '@/pages/documents/DocumentsList/components/skeletons';
import { openDocumentsModal } from '@/pages/documents/Modals/actions';
import { CreateFolderModalData } from '@/pages/documents/Modals/command';
import { FileTreeNode } from '@/pages/documents/types';
import { PlusCircle, UploadSimple } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import selector, { getBoatFileTreeNoData, getFleetFileTreeNoData } from './selector';
import useStyles from './styles';

const SCROLLABLE_CONTAINER_ID = 'all-documents';

const AllDocuments: FC = () => {
  const { boatItems, fleetItems, selectedNode, initialOpenedNodes, nodeUpdating, listLoading } =
    useAppSelector(selector);

  const styles = useStyles(nodeUpdating);
  const dispatch = useDispatch();

  const handleUploadFiles = (files: File[]) => {
    if (listLoading || nodeUpdating) {
      return;
    }

    dispatch(uploadFiles({ files, parent: 0, shared: false }));
  };

  const handleCreateNewFolder = () => {
    if (listLoading || nodeUpdating) {
      return;
    }

    dispatch(openDocumentsModal(new CreateFolderModalData(0, false)));
  };

  const handleDragNDrop = (_: FileTreeNode[], options: DropOptions<FileStorageNodeData>) => {
    dispatch(updateNodeParent(options));
  };

  const handleSelectNode = (node: Nullable<FileTreeNode>) => {
    dispatch(changeSelectedNode(node));
  };

  return (
    <>
      <ScrollMemory elementID={SCROLLABLE_CONTAINER_ID} />

      <Grid item container direction="column" gap={4} wrap="nowrap" sx={styles.container} id={SCROLLABLE_CONTAINER_ID}>
        <Grid item container direction="column" gap={2} wrap="nowrap">
          <LoadFileDropzone
            disabled={listLoading}
            reducerName="test_reducer"
            handleOnDrop={handleUploadFiles}
            render={(props, { getRootProps, getInputProps }) => (
              <div {...getRootProps({})}>
                <input {...getInputProps()} />

                <Button
                  variant="contained"
                  color="black"
                  fullWidth
                  sx={styles.uploadFilesButton}
                  label={
                    <Grid container gap={2}>
                      <SvgIcon>
                        <UploadSimple />
                      </SvgIcon>
                      <FormattedMessage id="label.upload_files" />
                    </Grid>
                  }
                />
              </div>
            )}
          />

          <Button
            variant="filled"
            color="black"
            sx={styles.createFolderButton}
            label={
              <Grid container gap={2}>
                <SvgIcon>
                  <PlusCircle />
                </SvgIcon>

                <Typography>
                  <FormattedMessage id="label.create_new_folder" />
                </Typography>
              </Grid>
            }
            onClick={handleCreateNewFolder}
          />
        </Grid>

        <Grid item container direction="column" gap={2} wrap="nowrap" sx={styles.treesContainer}>
          <NoDataContainer
            selector={getBoatFileTreeNoData}
            loader={<ListSkeleton />}
            noDataContent={<NoDataPlaceholder styles={{ marginBottom: 20 }} />}
          >
            <NodeTree
              nodes={boatItems}
              handleDragNDrop={handleDragNDrop}
              selectedNode={selectedNode}
              setSelectedNode={handleSelectNode}
              initialOpenedNodes={initialOpenedNodes}
              dataTestId="documents-node-tree"
            />
          </NoDataContainer>

          <Grid item>
            <Divider />
          </Grid>

          <NoDataContainer selector={getFleetFileTreeNoData} loader={<ListSkeleton />}>
            <NodeTree
              nodes={fleetItems}
              handleDragNDrop={handleDragNDrop}
              selectedNode={selectedNode}
              setSelectedNode={handleSelectNode}
              initialOpenedNodes={initialOpenedNodes}
              dataTestId="documents-fleet-node-tree"
            />
          </NoDataContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default AllDocuments;
