import State from '@/app/types/state';
import { NoDataContainerSelector } from '@/components/NoDataContainer/NoDataContainer';
import { createSelector } from 'reselect';

export const getDocumentsList = (state: State) => state.documents.list;
export const getCurrentItems = (state: State) => state.documents.list.value;
export const getSelectedNode = (state: State) => state.documents.list.selectedNode;
export const getLoading = (state: State) => state.documents.list.loading;
export const getNodeUpdating = (state: State) => state.documents.list.nodeUpdating;

export const getExpireTabsData = createSelector([getCurrentItems, getSelectedNode], (items, selectedNode) => ({
  items,
  selectedNode,
}));

export const getExpiredTabsNoData: NoDataContainerSelector = createSelector([getDocumentsList], (list) => ({
  loading: list.loading,
  loaded: list.loaded,
  hasData: list.hasData,
  error: list.error,
}));
