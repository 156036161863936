import { COLOURS } from '@core-ui/styles';
import SvgIcon from '@mui/material/SvgIcon';
import { MIME_TYPE_ENUM } from '@/app/consts/app';
import { FileTreeNode } from '@/pages/documents/types';
import { File, FileDoc, FileJpg, FilePdf, FilePng, FileXls } from '@phosphor-icons/react';
import React, { FC } from 'react';
import useStyles from './styles';

interface IProps {
  node: FileTreeNode;
  isSelected: boolean;
}

const ItemIcon: FC<IProps> = (props) => {
  const { node, isSelected } = props;

  const styles = useStyles();

  let icon: JSX.Element;

  switch (node.data?.file_type) {
    case MIME_TYPE_ENUM.JPG:
    case MIME_TYPE_ENUM.JPEG:
      icon = <FileJpg />;
      break;
    case MIME_TYPE_ENUM.PNG:
      icon = <FilePng />;
      break;
    case MIME_TYPE_ENUM.PDF:
      icon = <FilePdf />;
      break;
    case MIME_TYPE_ENUM.DOC:
    case MIME_TYPE_ENUM.DOCX:
      icon = <FileDoc />;
      break;
    case MIME_TYPE_ENUM.XLS:
    case MIME_TYPE_ENUM.OPEN_XML_SHEET:
      icon = <FileXls />;
      break;
    default:
      icon = <File />;
  }

  return (
    <SvgIcon htmlColor={isSelected ? COLOURS.Fig.Solid[600] : COLOURS.Coconut.Solid[900]} sx={styles.icon}>
      {icon}
    </SvgIcon>
  );
};

export default ItemIcon;
