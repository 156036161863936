import { Nullable } from '@core-ui/types';
import { buildURL, getParsedQuery, updateQuery } from '@core-ui/url';
import { DELETE } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { vehicleIdSelector } from '@/app/selectors';
import { ISagaContext } from '@/app/types/common';
import { showNotification } from '@/components/Notification/actions';
import history from '@/history';
import { DOCUMENTS_REQUEST_QUERY_PARAMS, FILE_STORAGE } from '@/pages/documents/const';
import { deleteMultipleNodes, deleteSingleNode, setSelectedNode } from '@/pages/documents/DocumentsList/actions';
import { getCurrentItems } from '@/pages/documents/DocumentsList/selector';
import { closeDocumentsModal, setDocumentsModalLoading } from '@/pages/documents/Modals/actions';
import { FileTreeNode, IDocumentListQueryParams } from '@/pages/documents/types';
import { buildChildrenIdArray } from '@/pages/documents/utils';
import { all } from '@redux-saga/core/effects';
import { Action } from 'redux-actions';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { IDeleteNode } from './actions';

function* deleteNode({ payload }: Action<IDeleteNode>) {
  try {
    const { node } = payload;
    const history: ISagaContext['history'] = yield getContext('history');

    yield put(setDocumentsModalLoading({ loading: true }));

    const boatId: number = yield select(vehicleIdSelector);

    const url = buildURL(`/${FILE_STORAGE}/${node.id}`, {
      [DOCUMENTS_REQUEST_QUERY_PARAMS.BOAT_ID]: boatId,
    });

    yield call(DELETE, url);

    const currentItems: Nullable<FileTreeNode[]> = yield select(getCurrentItems);
    const query = getParsedQuery<IDocumentListQueryParams>();

    // children of a node should also be deleted if there are any
    if (node.droppable) {
      const nodeIdsToDelete = buildChildrenIdArray(currentItems, node, []);
      nodeIdsToDelete.push(Number(node.id));

      if (nodeIdsToDelete.includes(Number(query.id))) {
        history.replace(updateQuery<IDocumentListQueryParams>({ id: undefined }));
        yield put(setSelectedNode(null));
      }

      yield put(deleteMultipleNodes(nodeIdsToDelete));
    } else {
      if (query.id === node.id) {
        history.replace(updateQuery<IDocumentListQueryParams>({ id: undefined }));
        yield put(setSelectedNode(null));
      }

      yield put(deleteSingleNode(node));
    }

    yield all([
      put(closeDocumentsModal()),
      put(
        showNotification({
          variant: 'success',
          titleId: node.droppable
            ? 'notification.success.text.delete_folder'
            : 'notification.success.text.delete_document',
        })
      ),
    ]);
  } catch (e) {
    yield all([put(setDocumentsModalLoading({ loading: false })), call(responseError, e)]);
  }
}

export default [takeLatest(actions.deleteNode, deleteNode)];
