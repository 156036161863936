import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  reloadButton: {
    '&:hover': {
      background: `${COLOURS.WHITE} !important`,
      borderColor: `${COLOURS.Fig.Solid[600]} !important`,
    },
  },
});
