import { isValidEmail, isValidPassword } from '@/app/utils/validate';
import { FormattedMessage } from 'components/Intl';
import { ILoginForm } from '../types';

export default (values: ILoginForm, hasLoginErrors: boolean) => {
  const errors: Record<string, unknown> = {};

  if (hasLoginErrors) {
    errors.email = <FormattedMessage id="form.errors.please_check_email" />;
    errors.password = <FormattedMessage id="form.errors.please_check_password" />;

    return errors;
  }

  if (!values.email || !values.password) {
    if (!values.email) {
      errors.email = <FormattedMessage id="form.errors.required_email" />;
    }

    if (!values.password) {
      errors.password = <FormattedMessage id="form.errors.required_password" />;
    }

    return errors;
  }

  if (!isValidEmail(values.email?.toLowerCase() ?? '')) {
    errors.email = <FormattedMessage id="form.errors.please_check_email" />;
  }

  if (!isValidPassword(values.password)) {
    errors.password = <FormattedMessage id="form.errors.password_must_be" />;
  }

  return errors;
};
