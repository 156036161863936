import { Nullable } from '@core-ui/types';
import { DndProvider, DropOptions, getBackendOptions, MultiBackend, NodeModel, Tree } from '@minoru/react-dnd-treeview';
import Grid from '@mui/material/Grid';
import { FileStorageNodeData } from '@/generated';
import { useAppSelector } from '@/hooks/useAppSelector';
import { FLEET } from '@/pages/documents/const';
import SingleNode from '@/pages/documents/DocumentsList/components/SingleNode';
import { getNodeUpdating } from '@/pages/documents/DocumentsList/selector';
import { FileTreeNode } from '@/pages/documents/types';
import React, { FC } from 'react';

interface IProps {
  nodes: FileTreeNode[];
  handleDragNDrop: (_: FileTreeNode[], options: DropOptions<FileStorageNodeData>) => void;
  selectedNode: Nullable<NodeModel>;
  setSelectedNode: (node: Nullable<FileTreeNode>) => void;
  initialOpenedNodes: (number | string)[];
  dataTestId?: string;
}

const NodeTree: FC<IProps> = (props) => {
  const { nodes, handleDragNDrop, selectedNode, setSelectedNode, initialOpenedNodes, dataTestId } = props;

  const nodeUpdating = useAppSelector(getNodeUpdating);

  const handleSelect = (node: FileTreeNode) => {
    setSelectedNode(node);
  };

  const handleDrop = (_: FileTreeNode[], options: DropOptions<FileStorageNodeData>) => {
    handleDragNDrop(_, options);
  };

  const canDrag = (node?: NodeModel<FileStorageNodeData>) => {
    return node?.id !== FLEET && !nodeUpdating;
  };

  return (
    <Grid item width="100%">
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <Tree<FileStorageNodeData>
          tree={nodes}
          rootId={0}
          onDrop={handleDrop}
          insertDroppableFirst
          enableAnimateExpand
          classes={{
            root: 'tree-root',
            dropTarget: 'drop-target',
            draggingSource: 'dragging-source',
            container: 'tree-container',
          }}
          canDrag={canDrag}
          initialOpen={initialOpenedNodes}
          render={(node: NodeModel<FileStorageNodeData>, options) => (
            <SingleNode
              nodeUpdating={nodeUpdating}
              node={node}
              onSelect={handleSelect}
              isSelected={node.id === selectedNode?.id}
              {...options}
            />
          )}
        />
      </DndProvider>
    </Grid>
  );
};

export default NodeTree;
