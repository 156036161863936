import Grid from '@mui/material/Grid';
import { SidePanelLeft } from '@/components/SidePanel';
import { FILE_TREE_DEFAULT_WIDTH, FILE_TREE_MAX_WIDTH, FILE_TREE_MIN_WIDTH } from '@/pages/documents/const';
import DocumentsListContent from '@/pages/documents/DocumentsList/components/DocumentsListContent';
import DocumentsListPanelTabs from '@/pages/documents/DocumentsList/components/DocumentsListPanelTabs';
import React, { FC } from 'react';
import useStyles from './styles';

const DocumentsList: FC = () => {
  const styles = useStyles();

  return (
    <SidePanelLeft
      defaultWidth={FILE_TREE_DEFAULT_WIDTH}
      minWidth={FILE_TREE_MIN_WIDTH}
      maxWidth={FILE_TREE_MAX_WIDTH}
    >
      <Grid container direction="column" gap={4} height="100%" width="100%" wrap="nowrap" sx={styles.container}>
        {/* todo: поиск пока отсутствует, мб добавим позже. Бэку нужно будет написать функцию фильтрации которая не выкидывает родителя если хотя бы один из его детей подходит под критерии поиска */}
        {/* <TextField */}
        {/*  fullWidth */}
        {/*  size="medium" */}
        {/*  label={<FormattedMessage id="filters.search_documents" />} */}
        {/*  // onChange={handleFilterChange('name')} */}
        {/*  // value={name} */}
        {/*  rightIcon1={ */}
        {/*    <SvgIcon> */}
        {/*      <MagnifyingGlass /> */}
        {/*    </SvgIcon> */}
        {/*  } */}
        {/* /> */}

        <DocumentsListPanelTabs />

        <DocumentsListContent />
      </Grid>
    </SidePanelLeft>
  );
};

export default DocumentsList;
